import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { common } from '@/store/stateless/common'
import { amend_invoices, amend_invoices_without_vat, allDocuments } from '@/store/modules/car/Customer'
import { useUser } from '@/store/composable/User'
import { totalRefunds } from '@/store/modules/car/Policy'

interface keyVariable {
    [key: string] : string | number | undefined
}

const { role_id, user_id } = useUser()
@Module
export default class Listing extends VuexModule {
    listing = []

    @Mutation
    ['SET_LISTING'](payload) {
        this.listing = payload
    }

    get leadsAndTasks() {
        return (data) => {
                return data.map(x => {
                    return {
                        customer_id: x.id,
                        hot_lead: x.hot_lead == 1,
                        hot_renewal_lead: x.hot_renewal_lead == 1,
                        name: x.name,
                        email: x.email,
                        phone_number: x.phone_number,
                        customer_status_id: x.customer_status_id,
                        customer_status_text: x.customer_status_text,
                        is_renewal: x.customer_has_renewal_lead == 1 ? 'Renewal' : '-',
                        open_task_count: x.lead_open_task_count,
                        task_due_date: x.lead_open_due_date_format,
                        sales_agent: x.agent,
                        time_lines: timelines(x)
                    }
                })
        }
    }

    get InvoiceData() {
        return (data, tab, route_name, isChild = false, others = null) => {
            return invoiceData(data, tab, route_name, isChild, others)
        }
    }


    get PolicyData() {
        return (data, route_name, isChild = false) => {
           if(route_name === 'policy-assigned') return completed(data, 2, isChild)
           else if(route_name === 'policy-completed') return completed(data, 4, isChild)
           else if(route_name === 'policy-cancellation-request') return completed(data, 5, isChild)
           else if(route_name === 'policy-refund-closed') return completed(data, 10, isChild)
           else if(route_name === 'policy-cancellation-approval') return completed(data, 6, isChild)
           else if(route_name === 'policy-cancelled') return completed(data, [7, 11], isChild)
           else if(route_name === 'others') return policyData(data, 2, isChild)
           else return policyData(data, 8, isChild)
        }
    }


    get marketingCampaingsListing() {
        return (data) => {
            if(data != undefined) {
                return data.map(x => {
                    return {
                        id: x.id,
                        name: x.name,
                        created_by_user_name: x.created_by_user.name,
                        created_at: x.created_at,
                        total_spent: x.total_spent,
                        show_delete_campaign:true
                    }
                })
            }
            return []                
        }
    }

    get getUserLists() {
        return (data) => {
            if(data) {
                return data.map(x => {
                    const displayStatus = x.status == 1 ? `${x.user_status}<br /> - `: `${x.user_status}<br />${x.inactivated_at}`
                    return {
                        id: x.id,
                        name: x.name,
                        role_id: x.role_id,
                        official_email: x.official_email,
                        username: x.email,
                        role: x.role.name,
                        status: x.user_status,
                        displayStatus : displayStatus,
                        created_updated: `${x.created_at}<br />${x.updated_at}`,
                        edit_path: `/user/edit/${x.id}`,
                        switch_path: `/user/switch/${x.id}`,
                    }
                })
            }
        }
    }

    get getRoundRobinUserLists() {
        return (data) => {
            if(data) {
                return data.map(x => {
                    const round_robin_updated_by = x.round_robin_setting && x.round_robin_setting.round_robin_updated_by_user ? x.round_robin_setting.round_robin_updated_by_user.name : '-';
                    const round_robin_updated_on = x.round_robin_setting && x.round_robin_setting.round_robin_updated_at_format != null ? x.round_robin_setting.round_robin_updated_at_format : '-';
                    return {
                        id: x.id,
                        name: x.name,
                        username: x.email,
                        official_email: x.official_email,
                        role: x.role.name,
                        skye_type: x.skye_type,
                        status: x.user_status,
                        is_round_robin: x.round_robin_setting ? x.round_robin_setting.is_round_robin : 0,
                        round_robin_updated_values: `${round_robin_updated_by}<br />${round_robin_updated_on}`,

                    }
                })
            }
        }
    }

}

function timelines(customer) {
    return {
        current_visited_date: customer.current_visited_date_format,
        last_visited_date: customer.last_visited_date_format,
        created_at: customer.created_at
    }
}

function completed (data, pStatus, isChild = false) {
    return data.map((item:any, index) => {
        let online_link = ''
        if(item.transaction_type == 1) online_link = item.payment_link
        if(item.transaction_type == 5) online_link = item.quote_ref_no

        let startDate = '-'
        if(item.policy_start_date) startDate = common.setDateNTOnly(item.policy_start_date)

        let endDate = '-'
        if(item.policy_end_date && item.policy_status !== 2) endDate =common.setDateNTOnly(item.policy_end_date)

        let created_on = `${item.policy_created_at ? common.setDateNT(item.policy_created_at) : '-'} <br /> ${item.policy_issue_date ? common.setDateNT(item.policy_issue_date) : '-'}`
        if(pStatus == 2) 
            created_on = `${item.policy_created_at ? common.setDateNT(item.policy_created_at) : '-'}`

        const isClaimMade = item.cannot_cancel_reason == 'claim' ? 'Claim Made' : ''
        let claimMade = '';
        if(isClaimMade == 'Claim Made') {
            claimMade = `<span class="table-color-span text-blue cursor-pointer open_claim_cancellation_documents_${index}">${isClaimMade}</span>`
        }
        const datas = {
            show_action: true,
            hasChildren: item.hasChildren,
            is_active: item.is_active,
            sid: item.sid,
            invoice_id: item.invoice_id,
            parent_invoice_id: item.parent_invoice_id,
            policy_id: item.policy_id,
            lead_id: item.lead_id,
            quote_ref_no: item.merchant_reference,
            status_text: item.policy_status_text,
            status_text_and_claim:`${item.policy_status_text}<br />${claimMade}`,
            status: item.policy_status,
            details: item.detail,
            policy_status: item.policy_policy_status,
            email: item.customer_email,
            customer_name: item.customer_name,
            quote_ref_no_amend: item.merchant_reference,
            policy_price: `${common.formatCurrency(item.policy_price)} (${common.formatCurrency(item.policy_price_vat)})`,
            policy_price_without_vat: common.formatCurrency(item.policy_price),
            invoice_amount: `${common.formatCurrency(item.sale_price)} (${common.formatCurrency(item.sale_price_vat)})`,
            invoice_amount_without_vat: common.formatCurrency(item.sale_price),
            price_vat_inline: `${common.formatCurrency(item.sale_price)} (${common.formatCurrency(item.sale_price_vat)})`,
            price_vat: `${common.formatCurrency(item.policy_price)} <br />${common.formatCurrency(item.policy_price_vat)}`,
            price: `${common.formatCurrency(item.policy_price)}`,
            amend_invoices: item.amend_invoices ? amend_invoices(item.amend_invoices) : [],
            amend_invoices_without_vat: item.amend_invoices ? amend_invoices_without_vat(item.amend_invoices) : [],
            discrepancy_amount: common.formatCurrency(item.discrepancy_amount ? item.discrepancy_amount : 0),
            transaction_type_text: item.transaction_type_text,
            transaction_type: item.transaction_type,
            online_link: item.online_link,
            policy_type: item.policy_type,
            policy_name: item.policy_name ? item.policy_name : '<span>-</span>',
            policy_number: item.policy_number ? item.policy_number : '-',
            policy_name_listing: item.policy_name ? '<span>'+item.policy_name+'<br/>'+ (item.policy_number ?? '-') +'</span>' : '<span>-</span>',
            created_on,
            underwriter: item.uw_name,
            agent: item.sa_name,
            policy_start_end_date: `${startDate}<br />${endDate}`,
            upsa: `${item.uw_name ? item.uw_name : '<span class="text-danger"> Not assigned</span>'}<br />${ item.sa_name ? item.sa_name : '-' }`,
            ibco: `${item.invoice_by_name ? item.invoice_by_name : '-'}<br />${ item.invoice_created_at ? common.setDateNT(item.invoice_created_at) : '-' }`,
            car_value: common.formatCurrency(item.car_value),
            insurance_payment_text: item.insurance_payment_text ? item.insurance_payment_text : '-',
            total_refunds: item.total_refunds,
            invoice_doc: item.invoice_doc,
            relation_quote_ref_no: item.relation_quote_ref_no,
            relation: item.relation,
            pStatus,
            type: 'policy',
            refund_created_by: item.refund ? item.refund.created_by : '',
            refund_created_at: item.refund ? common.setDateNTOnly(item.refund.created_at) : '',
            cannot_cancel_reason: item.cannot_cancel_reason,
            cancellation_type: item.cancellation_type,
            cancellation_reason: item.cancellation_reason,
            cancellation_reason_text: item.cancellation_reason_text,
            cancellation_note: item.cancellation_note,
            underwriter_cancellation_notes: item.underwriter_cancellation_notes,
            cancellation_request_date: item.cancellation_request_date,
            cancellation_requested_by: item.cancellation_requested_by,
            ...item.actions
        }

        if(pStatus == 10) {
            return {
                ...datas,
                refunded_on_by: `${item.refund && item.refund.refunded_on ? common.setDateNT(item.refund.refunded_on) : '-'} <br/> ${item.refund && item.refund.refund_by ? item.refund.refund_by : '-'}`,
                refund_completed_at_by: `${item.refund && item.refund.refunded_on ? common.setDateNT(item.refund.refunded_on) : '-'} <br/> ${item.refund && item.refund.refund_by ? item.refund.refund_by : '-'}`,
                approved_on_by: `${item.refund && item.refund.refund_approved_date ? common.setDateNT(item.refund.refund_approved_date) : '-'} <br/> ${item.refund && item.refund.approved_by ? item.refund.approved_by : '-'}`,
                refund_approved_at_by: `${item.refund && item.refund.refund_approved_date ? common.setDateNT(item.refund.refund_approved_date) : '-'} <br/> ${item.refund && item.refund.approved_by ? item.refund.approved_by : '-'}`,
                refund_created_at_by: `${item.refund && item.refund.created_at ? common.setDateNT(item.refund.created_at) : '-'}<br /> ${item.refund && item.refund.created_by ? item.refund.created_by : '-'}`,
                refund_reasons: item.refund ? item.refund.refund_reasons : '',
                refund_reasons_text: item.refund ? item.refund.refund_reasons_text : '',
                refund_reason: item.refund ? item.refund.refund_reasons_text : '',
                refund_note: item.refund ? item.refund.refund_note : '',
                refund_notes: item.refund ? item.refund.refund_note : '',
                refund_type: item.refund ? item.refund.refund_type : '',
                refund_payable_amount: item.refund ? item.refund.refund_payable_amount : 0,
                refund_payable_amount_agent: item.refund ? item.refund.refund_payable_amount_agent : 0,
                refund_payable_amount_customer: item.refund ? item.refund.refund_payable_amount_customer : 0,
                pay_to: item.refund ? item.refund.pay_to : 0,
                refund_status: item.refund ? parseInt(item.refund.refund_status) : 2,
                policy_status: parseInt(item.refund.policy_status),
                refund_account_note: item.refund ? item.refund.refund_account_note : ''
            }
        } else if([5, 6].includes(pStatus)) {
            return {
                ...datas,
                cancellation_note: item.cancellation_note,
                cancellation_type: item.cancellation_type,
                cancellation_type_text: item.cancellation_type_text,
                cancellation_reason: item.cancellation_reason,
                cancellation_reason_text: item.cancellation_reason_text ? item.cancellation_reason_text : '-',
                cancellation_payable_amount: parseFloat(item.cancellation_payable_amount),
                cancellation_payable_amount_display: common.formatCurrency(parseFloat(item.cancellation_payable_amount)),
                cancellation_credit_amount: parseFloat(item.cancellation_credit_amount),
                cancellation_credit_amount_display: common.formatCurrency(parseFloat(item.cancellation_credit_amount)),
                cancellation_credit_notes_no: item.cancellation_credit_notes_no ? item.cancellation_credit_notes_no : '-',
                cancellation_debit_amount: parseFloat(item.cancellation_debit_amount),
                cancellation_debit_amount_display: common.formatCurrency(parseFloat(item.cancellation_debit_amount)),
                cancellation_debit_notes_no: item.cancellation_debit_notes_no ? item.cancellation_debit_notes_no : '-',
                cancellation_endorsement_no: item.cancellation_endorsement_no ? item.cancellation_endorsement_no : '-',
                cancellation_date: item.cancellation_date,
                underwriter_cancellation_notes: item.underwriter_cancellation_notes,
                cancellation_status_id: item.cancellation_status_id,
                account_notes: item.cancellation_account_notes,
                cancellation_approved_date: item.cancellation_approved_date ? common.setDateNT(item.cancellation_approved_date) : '-',
                cancellation_underwriter_approved_date: item.cancellation_underwriter_approved_date ? common.setDateNT(item.cancellation_underwriter_approved_date) : '-',
                cancellation_request_date: common.setDateNT(item.cancellation_request_date),
                cancellation_request_date_by: `${item.cancellation_request_date ? common.setDateNT(item.cancellation_request_date) : '-'}<br />${item.cancelled_requested_by ? item.cancelled_requested_by : '-'}`,
                cancellation_process_date_by: `${item.cancellation_underwriter_approved_date ? common.setDateNT(item.cancellation_underwriter_approved_date) : '-'}<br />${item.cancelled_processed_by ? item.cancelled_processed_by : '-'}`,
                cancellation_approved_date_by: `${item.cancellation_approved_date ? common.setDateNT(item.cancellation_approved_date) : '-'}<br />${item.cancelled_approved_by ? item.cancelled_approved_by : '-'}`,
                cancellation_completed_date_by: `${item.cancellation_completed_date ? common.setDateNT(item.cancellation_completed_date) : '-'}<br />${item.cancelled_completed_by ? item.cancelled_completed_by : '-'}`,
                cancellation_requested_by: item.cancelled_requested_by,
                cancellation_requested_by_date: `${common.setDateNT(item.cancellation_request_date)}<br />${item.cancelled_requested_by}`
            }
        } else if(Array.isArray(pStatus) && [7, 11].every((element, index) => element === pStatus[index])) {
            return {
                ...datas,
                cancelled_on_by: `${item.cancellation_completed_date ? common.setDateNT(item.cancellation_completed_date) : '-'} <br> ${item.cancelled_completed_by ? item.cancelled_completed_by : '-'}`,
                approved_on_by: `${item.cancellation_approved_date ? common.setDateNT(item.cancellation_approved_date) : '-'} <br> ${item.cancelled_approved_by ? item.cancelled_approved_by : '-'}`,
                cancellation_type: item.cancellation_type,
                cancellation_type_text: item.cancellation_type_text,
                cancellation_note: item.cancellation_note,
                cancellation_reason: item.cancellation_reason,
                cancellation_reason_text: item.cancellation_reason_text ? item.cancellation_reason_text : '-',
                cancellation_payable_amount: parseFloat(item.cancellation_payable_amount),
                cancellation_payable_amount_display: common.formatCurrency(parseFloat(item.cancellation_payable_amount)),
                cancellation_credit_amount: parseFloat(item.cancellation_credit_amount),
                cancellation_credit_amount_display: common.formatCurrency(parseFloat(item.cancellation_credit_amount)),
                cancellation_credit_notes_no: item.cancellation_credit_notes_no ? item.cancellation_credit_notes_no : '-',
                cancellation_debit_amount: parseFloat(item.cancellation_debit_amount),
                cancellation_debit_amount_display: common.formatCurrency(parseFloat(item.cancellation_debit_amount)),
                cancellation_debit_notes_no: item.cancellation_debit_notes_no ? item.cancellation_debit_notes_no : '-',
                cancellation_endorsement_no: item.cancellation_endorsement_no ? item.cancellation_endorsement_no : '-',
                cancellation_date: item.cancellation_date,
                underwriter_cancellation_notes: item.underwriter_cancellation_notes,
                cancellation_status_id: item.cancellation_status_id,
                account_notes: item.cancellation_account_notes,
                cancellation_approved_date: item.cancellation_approved_date ? common.setDateNT(item.cancellation_approved_date) : '-',
                cancellation_underwriter_approved_date: item.cancellation_underwriter_approved_date ? common.setDateNT(item.cancellation_underwriter_approved_date) : '-',
                cancellation_request_date: common.setDateNT(item.cancellation_request_date),
                cancellation_request_date_by: `${item.cancellation_request_date ? common.setDateNT(item.cancellation_request_date) : '-'}<br />${item.cancelled_requested_by ? item.cancelled_requested_by : '-'}`,
                cancellation_process_date_by: `${item.cancellation_underwriter_approved_date ? common.setDateNT(item.cancellation_underwriter_approved_date) : '-'}<br />${item.cancelled_processed_by ? item.cancelled_processed_by : '-'}`,
                cancellation_approved_date_by: `${item.cancellation_approved_date ? common.setDateNT(item.cancellation_approved_date) : '-'}<br />${item.cancelled_approved_by ? item.cancelled_approved_by : '-'}`,
                cancellation_completed_date_by: `${item.cancellation_completed_date ? common.setDateNT(item.cancellation_completed_date) : '-'}<br />${item.cancelled_completed_by ? item.cancelled_completed_by : '-'}`,
                cancellation_requested_by: item.cancelled_requested_by,
                cancellation_requested_by_date: `${common.setDateNT(item.cancellation_request_date)}<br />${item.cancelled_requested_by}`,
                pay_to: parseInt(item.cancellaltion_pay_to)
            }
        } else {
            return datas
        }
    })
}

function policyData (data, pStatus, isChild = false) {
    
    return data.map((item:any) => {
        const shows = {
            showDelete: false,
            showDeleteOnUpload: false,
            showLog: true,
            showDownload: false
        }

        let x = item
        if(pStatus === 8) x = item.policy

        let startDate = '-'
        if(x.policy_start_date_format) startDate =x.policy_start_date_format
        let endDate = '-'
        if(x.policy_end_date_format && x.status !== 2) endDate =x.policy_end_date_format

        let online_link = ''
        if(x.transaction_type === 1) {
            online_link = x.car_lead_invoice.payment_link
            if(x.is_amend == 1) online_link = x.payment_link
        }
        if(x.transaction_type === 5) online_link = x.car_lead_invoice?.intransact_policy?.car_lead_quote?.quote_ref_no

        if(item.is_refund_request_on_completed_policy){
            x.is_refund_request_on_completed_policy = item.is_refund_request_on_completed_policy;
        }

        let refunded_on_by = ''
        let refunded_on = ''
        let refund_declined_on = ''
        let cancelled_on_by = ''
        let approved_on_by = ''
        let refund_created_at = ''
        let refund_created_at_by = ''
        let refund_approved_at_by = ''
        let refund_completed_at_by = ''
        let refund_created_by = ''
        let refund_reasons_text = '-'
        let refund_note = ''
        let refund_reasons = ''
        let refund_type = ''
        let refund_type_text = ''
        let refund_payable_amount_agent = ''
        let refund_payable_amount_customer = ''
        let refund_payable_amount = ''
        let refund_payable_amount_formated = ''
        let refund_status = ''
        let refund_request_id = 0
        let refund_account_note = ''
        let refund_category = 0
        let pay_to = parseInt(x.pay_to)
        let quote_ref_no = !isChild ? x.car_lead_quote?.quote_ref_no : x.merchant_reference
        let invoice_amount_without_vat = common.formatCurrency(x.car_lead_invoice.sale_price)
        let transaction_type_text = x.transaction_type_text
        let transaction_type = x.transaction_type
        let invoice_id = x?.car_lead_invoice?.id
        let price_vat = `${common.formatCurrency(x.car_lead_invoice ? x.car_lead_invoice.original_price : 0 )} <br />${common.formatCurrency(x.car_lead_invoice.original_price_vat)}`
        let price = common.formatCurrency(x.car_lead_invoice ? x.car_lead_invoice.original_price : 0)
        const invoice_doc = !isChild ? x.car_lead_invoice?.invoice_doc?.file_path : x.invoiceDoc?.file_path

        if(x.is_amend == 1) {
            transaction_type_text = x.payment_type_text
            transaction_type = x.payment_type
            price_vat = `${common.formatCurrency(x ? x.sale_price : 0 )} <br />${common.formatCurrency(x.vat)}`
            price = `${common.formatCurrency(x ? x.sale_price : 0 )}`
        }

        if([8, 10].includes(pStatus)) {
            if(item?.category === 2) {
                quote_ref_no = item?.invoice?.merchant_reference
                invoice_amount_without_vat = common.formatCurrency(item?.invoice?.amount)
                transaction_type_text = item?.invoice?.payment_type_text
                invoice_id = item?.invoice_id
            }
            refund_created_at = item ? item.created_at : '-'            
            refund_created_at = x.car_refund_request ? x.car_refund_request.created_at : '-'
            refund_created_at_by = `${item.created_at ? item.created_at : '-'}<br />${item && item.created_by ? item.created_by.name : '-'}`
            refund_approved_at_by = `${item.refund_approved_date ? item.refund_approved_date : '-'}<br />${item && item.refund_approved_by ? item.refund_approved_by.name : '-'}`
            refund_completed_at_by = `${item.refunded_on ? item.refunded_on : '-'}<br />${item && item.refunded_by ? item.refunded_by.name : '-'}`
            refund_reasons_text = item.refund_reasons_text ? item.refund_reasons_text : '-'
            refund_note = item.refund_note
            refund_type = item.refund_type
            refund_type_text = item?.refund_type_text
            refund_payable_amount_agent = item.refund_payable_amount_agent
            refund_payable_amount_customer = item.refund_payable_amount_customer
            refund_payable_amount = item.refund_payable_amount
            refund_request_id = item.id
            refund_category = item.category
            refund_payable_amount_formated = common.formatCurrency(item.refund_payable_amount)
            pay_to = parseInt(item.pay_to)
            refund_status = item.refund_status
            refund_account_note = item.refund_account_note
            refund_reasons = item.refund_reasons
            refund_created_by = x.car_refund_request && x.car_refund_request.created_by ? x.car_refund_request.created_by.name : ''
            refunded_on_by = `${x.car_refund_request ? x.car_refund_request.refunded_on : x.updated_at}<br />${ x.car_refund_request && x.car_refund_request.refunded_by ? x.car_refund_request.refunded_by.name : '-' }`
            approved_on_by = `${x.car_refund_request && x.car_refund_request.refund_approved_date ? x.car_refund_request.refund_approved_date : '-' }<br />${ x.car_refund_request && x.car_refund_request.refund_approved_by ? x.car_refund_request.refund_approved_by.name : '-' }`
            if(pStatus == 8) {
                refund_created_by = item && item.created_by ? item.created_by.name : ''
                refunded_on = item.refunded_on
                refund_declined_on = item.updated_at
                refunded_on_by = `${item ? item.refunded_on : x.updated_at}<br />${ item && item.refunded_by ? item.refunded_by.name : '-' }`
                approved_on_by = `${item && item.refund_approved_date ? item.refund_approved_date : '-' }<br />${ item && item.refund_approved_by ? item.refund_approved_by.name : '-' }`
                
            }
        }

        if(Array.isArray(pStatus) && [7, 11].every((element, index) => element === pStatus[index])) {
            cancelled_on_by = `${x.cancellation_completed_date ? x.cancellation_completed_date : '-'}<br />${x.cancellation_completed_by ? x.cancellation_completed_by.name : '-'}`
            approved_on_by = `${x.cancellation_approved_date ? x.cancellation_approved_date : '-'}<br />${x.cancellation_approved_by ? x.cancellation_approved_by.name : '-'}`
        }

        let created_on = `${x.created_at ? x.created_at : '-'} <br /> ${x.issue_date ? x.issue_date : '-'}`

        if(pStatus == 2)
            created_on = `${x.created_at ? x.created_at : '-'}`
        
    return {
        show_action: true,
        total_refunds: totalRefunds(x.car_refund_completed),
        invoice_doc,
        is_active: x.is_active === 1,
        invoice_number: x.car_lead_invoice.merchant_reference,
        policy_sales_agent_id: x.policy_sales_agent_id,
        cancellation_type: x.cancellation_type,
        cancellation_type_text: x.cancellation_type_text,
        cancellation_note: x.cancellation_note,
        cancellation_reason: x.cancellation_reason,
        cancellation_reason_text: x.cancellation_reason_text ? x.cancellation_reason_text : '-',
        cancellation_payable_amount: parseFloat(x.cancellation_payable_amount),
        cancellation_payable_amount_display: common.formatCurrency(parseFloat(x.cancellation_payable_amount)),
        cancellation_credit_amount: parseFloat(x.cancellation_credit_amount),
        cancellation_credit_amount_display: common.formatCurrency(parseFloat(x.cancellation_credit_amount)),
        cancellation_credit_notes_no: x.cancellation_credit_notes_no ? x.cancellation_credit_notes_no : '-',
        cancellation_debit_amount: parseFloat(x.cancellation_debit_amount),
        cancellation_debit_amount_display: common.formatCurrency(parseFloat(x.cancellation_debit_amount)),
        cancellation_debit_notes_no: x.cancellation_debit_notes_no ? x.cancellation_debit_notes_no : '-',
        cancellation_endorsement_no: x.cancellation_endorsement_no ? x.cancellation_endorsement_no : '-',
        cancellation_date: x.cancellation_date,
        refund_request_id,
        refunded_on_by,
        refunded_on,
        refund_declined_on,
        cancelled_on_by,
        approved_on_by,
        refund_created_at,
        refund_created_at_by,
        refund_approved_at_by,
        refund_completed_at_by,
        refund_reasons_text,
        refund_note,
        refund_reasons,
        refund_category,
        refund_type,
        refund_type_text,
        refund_payable_amount_agent,
        refund_payable_amount_customer,
        refund_payable_amount,
        refund_payable_amount_formated,
        refund_status,
        refund_account_note,
        refund_created_by,
        pay_to,
        cancellation_status_id: x.cancellation_status_id,
        account_notes: x.account_notes,
        underwriter: x.policy_underwriter?.name,
        agent: x.policy_sales_agent?.name,
        cancellation_request_date: x.cancellation_request_date,
        underwriter_cancellation_notes: x.underwriter_cancellation_notes,
        online_link,
        sid: x.sid,
        hasChildren: x.hasChildren,
        invoice_id,
        is_amend: x.is_amended === 1 ? true : false,
        quote_ref_no,
        quote_ref_no_amend: x.car_lead_quote.quote_ref_no,
        status_text: isChild ? x.invoice_status_text : x.status_text,
        status: x.status,
        details: `${x.car_lead?.car_year} | ${x.car_lead?.make} | ${x.car_lead?.model} | ${x.car_lead?.model_details}`,
        policy_price: `${common.formatCurrency(x.car_lead_invoice.original_price)} (${common.formatCurrency(x.car_lead_invoice.original_price_vat)})`,
        policy_price_without_vat: `${common.formatCurrency(x.car_lead_invoice.original_price)}`,
        invoice_amount: `${common.formatCurrency(x.car_lead_invoice.sale_price)} (${common.formatCurrency(x.car_lead_invoice.vat)})`,
        invoice_amount_break: `${common.formatCurrency(x.car_lead_invoice.sale_price)}<br />${common.formatCurrency(x.car_lead_invoice.vat)}`,
        invoice_amount_without_vat,
        email: `${x.customer.email}`,
        customer_name: x.customer.name,
        phone_number: x.customer.phone_number,
        price_vat: price_vat,
        price: common.formatCurrency(x.car_lead_invoice ? x.car_lead_invoice.original_price : 0),
        price_vat_inline: `${common.formatCurrency(x.policy_price_amount)} (${common.formatCurrency(x.car_lead_invoice.vat)})`,
        price_inline: common.formatCurrency(x.policy_price_amount),
        amend_invoices: x?.car_lead_invoice?.amend_invoices ? amend_invoices(x.car_lead_invoice.amend_invoices) : [],
        amend_invoices_without_vat: x?.car_lead_invoice?.amend_invoices ? amend_invoices_without_vat(x.car_lead_invoice.amend_invoices) : [],
        discrepancy_amount: common.formatCurrency(x?.car_lead_invoice?.discrepancy_amount ? x.car_lead_invoice.discrepancy_amount : 0),
        transaction_type_text,
        transaction_type: transaction_type,
        policy_type: x?.car_insurance_policy?.policy_type == 5 ? 'TPL' : 'COMP',
        policy_name: x?.car_insurance_policy?.policy_name ? x.car_insurance_policy.policy_name : '<span>-</span>',
        policy_id: x.id,
        lead_id: x.lead_id,
        policy_start_end_date: `${startDate}<br />${endDate}`,
        created_on,
        policy_start_date: x.policy_start_date_format ? x.policy_start_date_format : '-',
        policy_end_date: x.policy_end_date_format ? x.policy_end_date_format : '-',
        upsa: `${x.policy_underwriter ? x.policy_underwriter.name : '<span class="text-danger"> Not assigned</span>'}<br />${ x.policy_sales_agent ? x.policy_sales_agent.name : '-' }`,
        ibco: `${ x.car_lead_invoice ? x.car_lead_invoice.created_at : '-' }<br />${x?.car_lead_invoice?.created_by ? x.car_lead_invoice.created_by.name : '-'}`,
        car_value: common.formatCurrency(x.car_lead ? x?.car_lead.car_value : 0),
        // car_value: common.formatCurrency(x?.car_lead_quote?.car_lead_driver_details ? x?.car_lead_quote?.car_lead_driver_details?.car_value : 0),
        insurance_payment_text: x.insurance_payment_text ? x.insurance_payment_text : '-',
        policy_document: x.policy_document ? x.policy_document.file_path : '',
        policy_documents: allDocuments(x.car_lead_documents, x.car_lead_quote.quote_ref_no, 'policy', true, [], shows),
        temp_documents: allDocuments(x.temp_document, x.car_lead_quote.quote_ref_no, 'temp', true, [], shows),
        cancellation_documents: allDocuments(x.car_lead_cancellation_documents, x.car_lead_quote.quote_ref_no, 'cancellation', true),
        hide_document_show_quote_ref_no: true,
        pStatus,
        type: 'policy',
        ...policyShowActions(x, pStatus, isChild, invoice_doc ? true : false)
    }
})
}

function policyShowActions(data, status, isChild = false, hasInvoiceDoc = false) {
    let show_update_data = false
    let show_edit_policy_details = false
    let show_view_policy_details = false
    let show_view_policy = false
    let show_view_all_documents = false
    let show_transfer_sale = false
    let show_change_underwriter = false
    let show_cancel_policy = false
    let show_refunds = false
    let show_initiate_ge = false
    let show_view_receipt = false
    let show_view_cancellationDocuments = false

    let show_edit_refund_details = false
    let show_view_refund_details = false
    let show_edit_cancellation_details = false
    let show_view_cancellation_details = false

    show_view_policy = [1, 9, 13, 2, 4, 6, 14, 15].includes(role_id) && data.policy_document && Object.keys(data.policy_document).length > 0 ? true : false
    show_view_all_documents = [1, 9, 13, 2, 4, 6, 14, 15].includes(role_id) && ((data.car_lead_documents && data.car_lead_documents.length > 0) || (data.temp_document && data.temp_document.length > 0)) ? true : false

    if(!show_view_policy && data?.is_refund_request_on_completed_policy){
        /**
         * Show view policy on the approve and view refunds.
         */
        show_view_policy = ([1, 9, 13, 2, 4, 6, 14, 15].includes(role_id) && data.is_refund_request_on_completed_policy)
    }

    if([2, 4].includes(status)) {
        show_view_cancellationDocuments = data.status === 7 ? true : false

        if([1, 4, 14].includes(role_id) && data.is_active === 1) {
            show_edit_policy_details = true
        }

        if([1, 9, 14].includes(role_id) && data.is_active === 1) show_transfer_sale = true
        if([1, 4, 9, 14].includes(role_id) && data.is_active === 1) show_change_underwriter = true
        if([1, 9, 13, 2].includes(role_id) && data.cancellation_initiated === 0 && data.is_active === 1 && !data.car_refund_request_pending && status != 2) show_cancel_policy = true
        if([13, 9, 2, 6].includes(role_id) && [10, 2,4].includes(status) || data.is_active === 0) show_view_policy_details = true
        if([1, 2, 9, 13].includes(role_id) && !data.cancellation_initiated && data.is_active && [4, 2].includes(data.status) && !data.car_refund_request_pending) show_refunds = true
        if([1, 4].includes(role_id) && status === 4 && data.is_active === 1) show_initiate_ge = true
        if(data.carLeadInvoice.payment_type !== 1 && hasInvoiceDoc) show_view_receipt = true
        if([14,1].includes(role_id) && status === 4) show_update_data = true
        // if(status === 2 && role_id == 14) show_view_receipt = false
    } else {
        
        if(status === 5) {
            // cancellation request
            if(![9, 7].includes(data.status) && [4, 1, 9].includes(role_id) && !data.car_refund_request_pending) show_edit_cancellation_details = true
            if([7].includes(data.status)) {
                if([14,1].includes(role_id)) show_update_data = true

                show_view_cancellation_details = true
            }
        }

        if(status === 6) {
            if([1, 9, 13, 2, 4, 6, 14].includes(role_id)) show_view_policy_details = true
            if([6, 1, 9].includes(role_id) && ![9, 7].includes(parseInt(data.cancellation_status_id))) show_edit_cancellation_details = true
            if(parseInt(data.cancellation_status_id) === 7) show_view_cancellation_details = true
            if(data.car_lead_cancellation_documents && data.car_lead_cancellation_documents.length > 0) show_view_cancellationDocuments = true
        }

        if(status === 10) {
            if(parseInt(data.car_refund_request?.refund_status) === 2) show_view_refund_details = true
            if([1, 9, 13, 2, 4, 6, 14].includes(role_id)) show_view_policy_details = true
            
        }

        if(status === 8) {
            if([1, 9, 6, 15, 14].includes(role_id)) show_view_policy_details = true
            if([1, 9, 6].includes(role_id) && ![2, 4].includes(parseInt(data.car_refund_request?.refund_status))) show_edit_refund_details = true
            if(parseInt(data.car_refund_request?.refund_status) === 2) show_view_refund_details = true
        }

        if(Array.isArray(status) && [7, 11].every((element, index) => element === status[index])) {
            // cancellation completed
            show_view_cancellation_details = true
            if([14, 1].includes(role_id)) show_update_data = true
            if([1, 9, 13, 2, 4, 6, 14].includes(role_id)) show_view_policy_details = true
            show_view_cancellationDocuments = true
            if([1, 4, 9, 14].includes(role_id) && (status[0] == 7)) show_change_underwriter = true
        }
    }
    
    if(isChild) {
        if(data.carLeadInvoice.payment_type !== 1 && hasInvoiceDoc) show_view_receipt = true
        show_update_data = false
        show_view_policy = false
        show_view_all_documents = false
        show_view_cancellationDocuments = false
        show_view_policy_details = false
        show_edit_policy_details = false
        show_change_underwriter = false
        show_cancel_policy = false
        show_refunds = false
        show_initiate_ge = false
        show_transfer_sale = false
        show_edit_refund_details = false
        show_edit_cancellation_details = false
        show_view_cancellation_details = false
    }

    return {
        show_update_data,
        show_view_policy,
        show_view_all_documents,
        show_view_cancellationDocuments,
        show_view_policy_details,
        show_edit_policy_details,
        show_change_underwriter,
        show_cancel_policy,
        show_refunds,
        show_initiate_ge,
        show_view_receipt,
        show_transfer_sale,
        show_edit_refund_details,
        show_view_refund_details,
        show_edit_cancellation_details,
        show_view_cancellation_details
    }
}

function invoiceData(data, tab, route_name, isChild = false, others:any = null) {
    return data.map((item:any) => {
        const x = item

        const quote_ref_no = x.merchant_reference
        let relation_quote_ref_no = x.invoice_number
        const price_inline = x.is_amend == 1 ? x.amount : x.original_price
        const vat_inline = x.is_amend == 1 ? x.vat : x.original_price_vat
        let relation = ''
        if(x.amend_type != 3 && x.is_amend == 1) relation = 'Parent'
        if(x.amend_type == 3) relation = 'Original'
        
        let transaction_type_text = x.payment_type_text
        let payment_link = ''
        if(x.payment_type_text == 'Online') {
            transaction_type_text = x.payment_type_text ? `${x.payment_type_text} <br /> ${x.bank_transaction_code ? x.bank_transaction_code : ''}` : x.payment_type_text
            payment_link = x.payment_link
        }
        if(x.payment_type_text == 'Intrasact') {
            transaction_type_text = x.payment_type_text
            payment_link = x.intransact_policy && x.intransact_policy.car_lead_quote ? x.intransact_policy.car_lead_quote.quote_ref_no : '-'
        }

        if(relation == '') relation_quote_ref_no = null

        let upsa = `${x.policy_purchase && x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : '<span class="text-danger"> Not assigned</span>'}<br />${ x.policy_sales_agent ? x.policy_sales_agent.name : '-' }`
        let hasChildren = x.hasChildren



        let hasOption = false
        let show_action = true

        if(['failed_payments_count', 'online_count', 'declined'].includes(tab)) hasChildren = false
        if(['cod_direct_count', 'manual_order_count'].includes(tab) && [1, 6].includes(role_id) && x.status != 6) hasOption = true
        if(tab == 'online_count' && x.payment_type == 1 && x.status != 6 && ([1, 6].includes(role_id) || x.policy_sales_agent_id == user_id)) hasOption = true
        if(tab == 'discrepancy_count' && x.is_discrepancy == 1 && [1, 6].includes(role_id)) hasOption = true
        if(isChild) hasOption = false
        if(tab == 'failed_payments_count') show_action = false

        let ibco = `${x.created_at}<br />${(x.created_by ? `${x.created_by.name}`: '-')}`
        let policy_name = x.policy ? x.policy.policy_name : '-'

        if(route_name == 'policy-approval') hasChildren = false

        const values = {
            type: 'invoice',
            show_action,
            is_active: x.is_active === 1,
            sid: x.sid,
            hasChildren,
            invoice_id: x.id,
            quote_ref_no,
            relation_quote_ref_no,
            relation,
            status: x.status,
            status_text: x.status_text,
            email: x.customer ? x.customer.email : '',
            customer_name: x.customer ? x.customer.name : '',
            order_description: x.order_description,
            reference_no: x.reference_no,
            payment_date: x.payment_date ? x.payment_date : '-',
            price_vat: `${common.formatCurrency(x.sale_price)} ${(x.is_manual_order == 1 && x.total_manual_orders != 0 ? `/ ${common.formatCurrency(x.total_manual_orders)}`  : '')} <br />${common.formatCurrency(x.vat)}`,
            price: common.formatCurrency(x.sale_price),
            quote_ref_no_amend: x.merchant_reference,
            price_vat_inline: `${common.formatCurrency(price_inline)} (${common.formatCurrency(vat_inline)})`,
            price_inline: `${common.formatCurrency(price_inline)}`,
            amend_invoices: amend_invoices_data(x.child_invoices),
            amend_invoices_without_vat: amend_invoices_without_vat_data(x.child_invoices),
            discrepancy_amount: x.discrepancy_amount,
            discrepancy_status: x.discrepancy_status ? x.discrepancy_status : '-',
            payment_link,
            transaction_type_text,
            transaction_type: x.payment_type,
            upsa,
            hasOption,
            ibco,
            approved_by_on: `${x.invoice_approved_by ? x.invoice_approved_by.name : '-'} <br /> ${x.invoice_approved_at ? x.invoice_approved_at : '-'}`,
            policy_type: x.policy && x.policy.policy_type == 5 ? 'TPL' : 'Comp',
            policy_name,
            tab,
            is_amend: x.is_amend,
            invoice_doc: x.invoice_doc ? x.invoice_doc.file_path : null,
            is_manual_order: x.is_manual_order,
            parent_invoice_id: x.parent_invoice_id,
            policy_purchase: x.policy_purchase,
            ...invoiceShowActions(x, tab, isChild)
        }

        if(x.is_manual_order) {

            // console.log({data, tab, isChild, others_by_you: others})
            if(isChild) {
                upsa = others.upsa
                ibco = others.ibco
                policy_name = others.policy_name
            }
        return {
                ...values,
                email: x.email,
                upsa,
                ibco,
                status_text: '',
                policy_name,
                manual_orders: x.manual_orders,
                show_invoice_details: isChild ? false : true
            }
        }

        return values
    })
}

function invoiceShowActions(data, tab, isChild = false) {
    let show_amend_invoice = false
    let show_invoice_view_receipt = false
    let show_upload_receipt = false
    let show_invoice_details = false
    let show_initiate_refund = false

    const is_active = data.policy_purchase && data.policy_purchase.is_active === 1 ? true : false

    if(data.is_show_amend_invoice && is_active && ![6, 14].includes(role_id)) show_amend_invoice = true
    if(data.payment_type != 1) {
        if(data.invoice_doc) show_invoice_view_receipt = true
        if([1, 6, 14].includes(role_id)) show_upload_receipt = true
    } 
    if([1, 9, 13, 2, 6, 14, 15].includes(role_id)) show_invoice_details = true
    if(tab == 'amend_invoice' && is_active && data.parent_invoice_id != 0 && [1, 6].includes(role_id)) show_initiate_refund = true

    if(tab == 'declined') {
        show_amend_invoice = false
        show_invoice_view_receipt = false
        show_upload_receipt = false
        show_invoice_details = true
        show_initiate_refund = false
    }

    return {
        show_amend_invoice,
        show_invoice_view_receipt,
        show_upload_receipt,
        show_invoice_details,
        show_initiate_refund
    }
}

function amend_invoices_data(invoices) {
    if(invoices && invoices.length > 0){
        return invoices.map(x => {
            const price_inline = x.is_amend == 1 ? x.amount : x.original_price
            const vat_inline = x.is_amend == 1 ? x.vat : x.original_price_vat

            return {
                quote_ref_no_amend: x.merchant_reference,
                price_vat_inline: `${common.formatCurrency(price_inline)} (${common.formatCurrency(vat_inline)})`
            }
        })
    }

    return []
}

function amend_invoices_without_vat_data(invoices) {
    if(invoices && invoices.length > 0){
        return invoices.map(x => {
            const price_inline = x.is_amend == 1 ? x.amount : x.original_price

            return {
                quote_ref_no_amend: x.merchant_reference,
                price_vat_inline: `${common.formatCurrency(price_inline)}`
            }
        })
    }

    return []
}